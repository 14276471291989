<template>
    <transition name="modal_fade">
        <div class="modal_backdrop" >
            <div class="modal" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription" v-draggable>

                <div class="modal_header">
                    New Variant
                    <button type="button" class="modal_btn_close" @click="close(false)" aria-label="Close modal"><span class="glyphicon glyphicon-remove"></span></button>
                </div>

                <div class="modal_body">
                    <form>
                        <div class='form-group'>
                            <label>Insulation Size</label>
                            <select class='form-control' v-model='formFields.insulationSize.val'>
                                <option v-for='[index, insulationSize] in cache.curbCache.INSULATION_SIZE' v-bind:key='index' :value='index'>{{ insulationSize }}</option>
                            </select>
                        </div>
                        <div class='form-group'>
                            <label>Insulation Type</label>
                            <select class='form-control' v-model='formFields.insulationType.val'>
                                <option v-for='[index, insulationType] in cache.curbCache.INSULATION_TYPE' v-bind:key='index' :value='index'>{{ insulationType }}</option>
                            </select>
                        </div>
                        <div class='form-group'>
                            <label>Material Type</label>
                            <select class='form-control' v-model='formFields.metalType.val'>
                                <option v-for='[index, metalType] in cache.curbCache.$METAL_TYPE' v-bind:key='index' :value='index'>{{ metalType }}</option>
                            </select>
                        </div>
                        <div class='form-group'>
                            <label>Wood Nailer</label>
                            <select class='form-control' v-model='formFields.woodNailer.val'>
                                <option v-for='[index, woodNailer] in cache.curbCache.WOOD_NAILER' v-bind:key='index' :value='index'>{{ woodNailer }}</option>
                            </select>
                        </div>
                        <div class='form-group'>
                            <label>Flex Ducting</label>
                            <select class='form-control' v-model='formFields.flexDucting.val'>
                                <option v-for='[index, flexDucting] in cache.curbCache.FLEX_DUCTING' v-bind:key='index' :value='index'>{{ flexDucting }}</option>
                            </select>
                        </div>
                        <div class='form-group'>
                            <label>Floor</label>
                            <select class='form-control' v-model='formFields.floor.val'>
                                <option v-for='[index, floor] in cache.curbCache.FLOOR' v-bind:key='index' :value='index'>{{ floor }}</option>
                            </select>
                        </div>
                        <div class='form-group'>
                            <label>Insulated Floor</label>
                            <select class='form-control' v-model='formFields.insulationFloor.val'>
                                <option v-for='[index, insulationFloor] in cache.curbCache.INSULATION_FLOOR' v-bind:key='index' :value='index'>{{ insulationFloor }}</option>
                            </select>
                        </div>
                        <div class='form-group'>
                            <label>New Config</label>
                            <select class='form-control' v-model='formFields.tunnel.val'>
                                <option v-for='[index, tunnel] in cache.curbCache.TUNNEL' v-bind:key='index' :value='index'>{{ tunnel }}</option>
                            </select>
                        </div>
                        <div class='form-group'>
                            <label>Divider Wall</label>
                            <select class='form-control' v-model='formFields.dividerWall.val'>
                                <option v-for='[index, dividerWall] in cache.curbCache.DIVIDER_WALL' v-bind:key='index' :value='index'>{{ dividerWall }}</option>
                            </select>
                        </div>
                        <div class='form-group'>
                          <label>Damper Tray</label>
                          <select class='form-control' v-model='formFields.damperTray.val'>
                            <option v-for='[index, damperTray] in cache.curbCache.DAMPER_TRAY' v-bind:key='index' :value='index'>{{ damperTray }}</option>
                          </select>
                        </div>
                        <div class='form-group'>
                          <label>Acoustical</label>
                          <select class='form-control' v-model='formFields.acoustical.val'>
                            <option v-for='[index, acoustical] in cache.curbCache.ACOUSTICAL' v-bind:key='index' :value='index'>{{ acoustical }}</option>
                          </select>
                        </div>
                        <div class='form-group'>
                          <label>Adapter Clip</label>
                          <select class='form-control' v-model='formFields.adapterClip.val'>
                            <option v-for='[index, adapterClip] in cache.curbCache.ADAPTER_CLIP' v-bind:key='index' :value='index'>{{ adapterClip }}</option>
                          </select>
                        </div>
                        <div class='form-group'>
                          <label>Canted</label>
                          <select class='form-control' v-model='formFields.canted.val'>
                            <option v-for='[index, canted] in cache.curbCache.CANTED' v-bind:key='index' :value='index'>{{ canted }}</option>
                          </select>
                        </div>
                    </form>
                </div>

                <div class="modal_footer">
                    <button type="button" class="btn btn-primary" @click="create" aria-label="Close modal">Create</button>
                    <button type="button" class="btn btn-default" @click="close" aria-label="Close modal">Close</button>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import curbCache from '@/cache/curb.cache.js'
    import { store } from '@/store/BusinessObject.store.js';
    import helpers from "../../../store/helpers";
    import draggable from "@/directives/draggable";

    export default {
        name: 'NewVariantModal',
        props: ['defaults'],
        directives: {
            draggable
        },
        data() {
            return {
                state: store.state,
                cache: {
                    curbCache
                },
                formFields: {
                    insulationSize: {val: 0, qsField: '[insulation_size]'},
                    insulationType: {val: 0, qsField: '[insulation_type]'},
                    metalType:      {val: 0, qsField: '[metal_type]'},
                    woodNailer:     {val: 0, qsField: '[wood_nailer]'},
                    flexDucting:    {val: 0, qsField: '[flex_ducting]'},
                    floor:          {val: 0, qsField: '[floor]'},
                    insulationFloor:{val: 0, qsField: '[insulation_floor]'},
                    tunnel:         {val: 0, qsField: '[tunnel]'},
                    dividerWall:    {val: 0, qsField: '[divider_wall]'},
                    damperTray:     {val: 0, qsField: '[damper_tray]'},
                    acoustical:     {val: 0, qsField: '[acoustical]'},
                    adapterClip:     {val: 0, qsField: '[adapter_clip]'},
                    canted:     {val: 0, qsField: '[canted]'},
                }
            }
        },
        methods: {
            create: function() {
                store.api('new_variant', helpers.getQSPairs(this.formFields), 'object.data');
                this.$emit('close');
                this.setDefaults();
            },
            close: function() {
                this.setDefaults();
                this.$emit('close');
        },
            setDefaults() {
                this.formFields.insulationSize.val = this.defaults.insulation_size;
                this.formFields.insulationType.val = this.defaults.insulation_type;
                this.formFields.metalType.val = this.defaults.metal_type;
                this.formFields.woodNailer.val = this.defaults.wood_nailer;
                this.formFields.flexDucting.val = this.defaults.flex_ducting;
                this.formFields.floor.val = this.defaults.floor;
                this.formFields.tunnel.val = this.defaults.tunnel;
                this.formFields.insulationFloor.val = this.defaults.insulation_floor;
                this.formFields.dividerWall.val = this.defaults.divider_wall;
                this.formFields.damperTray.val = this.defaults.damper_tray;
                this.formFields.acoustical.val = this.defaults.acoustical;
                this.formFields.adapterClip.val = this.defaults.adapter_clip;
                this.formFields.canted.val = this.defaults.canted;
            }
        },
        watch: {
            defaults: function() {
                this.setDefaults();
            }
        }
    }
</script>

<style scoped>
.modal{
    width: 600px;
    overflow-y: scroll;
    top: 50%!important;
    height: 80%;
}
@media (max-width: 850px)   {
    .modal{
        width: fit-content;
    }

}
</style>