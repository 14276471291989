export default {
	// cdi_lookup table with type == 21
	REASONS: [
		[253, 'Cycle Count'],
		[254, 'Damage'],
		[255, 'Obsolete'],
		[256, 'Production Error'],
		[257, 'Return'],
		[258, 'Build Stock'],
		[259, 'Fill Order'],
		[260, 'Other']
	]
}