<template>
    <div >
        <div class="row">
            <div class="col-sm-12">
                <table class="table  table-condensed">
                    <tbody>
                    <tr v-for="(label, object) in fileObjects" v-bind:key="object">
                        <th><strong>{{ label }}</strong></th>
                        <td>
                            <div class="col-sm-6">
                                <ProtectedLink :id="getDrawingData(object).cdi_file_id" :linkType="'iconText'" :text="getDrawingData(object).title" :queryString="queryString" :type="'download'" />
                            </div>
                            <div class="col-sm-6">
                                <div class="btn-group pull-right">
                                    <button v-show="!getDrawingData(object).file_name" class="btn btn-default disabled" ><span class="glyphicon glyphicon-cloud-upload"></span></button>
                                    <button class="btn btn-default" v-show="getDrawingData(object).file_name" @click="$refs.deleteFile.open(getDrawingData(object).cdi_file_id, id)"><span class="glyphicon glyphicon-trash"></span></button>
                                </div>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

        </div>
        <Confirm ref="deleteFile" :heading="'Disable File?'" :body="'Would you like to disable this file?'" @response="deleteFile" />
    </div>
</template>

<script>
import { store } from "@/store/BusinessObject.store";
import helpers from "@/store/helpers";
import ProtectedLink from "@/components/utils/ProtectedLink";
import Confirm from "@/components/utils/Confirm";

export default {
    name: "Files",
    components: {
        ProtectedLink,
        Confirm
    },
    props: ['data'],
    data () {
        return {
            fileObjects: {
                drawing_3d: '3D',
                engineering_file: 'Engineering File',
                eng_file_19: 'Welding File',
                eng_file_20: 'Kit Curb Brake',
                eng_file_33: 'Assembly Part Label',
                eng_file_35: 'Field Assembly',
                eng_file_37: 'Production Notes',
                eng_file_39: 'E-Drawing',
                conforming_stamp: 'Legacy Conforming Stamp'
            },
            deleteEndpoint: 'disable_curb_file',
            state: store.state,
            id: 0,
            fileTypeID: 0,
            cdi_file_id: 0
        }
    },
    methods: {
        changeView: function(data) {
            this.$emit('changeView', data);
        },
        deleteFile: function(data) {
            let localThis = this;
            if (data) {
                store.apiGeneric(`curb/disable_curb_file/${data}`, {params: data}).then(function(response) {
                    if (response.opt === 'save') {
                        localThis.$emit('reload');
                    }
                });
            }
        },
        refresh: function(){
            window.location.reload()
        },
        post: function(data) {
            let options = {};

            if (data.val) {
                let value = data.val;
                options = { 'params': value };
            }
            store.api(data.endPoint, options, 'object.data');

        },
        getDrawingData: function (key) {
            this.id = this.state.object.id;
            if (this.data[key] !== null && typeof this.data[key] !== 'undefined') {
                return this.data[key];
            }
            return {};
        }
    },
    computed: {
        queryString: function() {
            const params = helpers.getQSString({ 'params' : this.companyId});
            return `genid=${Math.floor(Math.random()*101)}&${params}`;
        }
    },
}
</script>

<style scoped>
.glyphicon-trash:hover{
    cursor: pointer;
}

th,td {
    vertical-align: inherit !important;
    text-align: center;
}
</style>