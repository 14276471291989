<template>
    <div>
        <div v-if="id">
            <h1>{{ state.object.data.nomenclature }}</h1>
            <div class="well well-sm">
                <div v-html="state.object.data.html_description"></div>
            </div>

            <div v-show="isCurrentView('details')">
                <div class="margin_bottom row">
                    <div class="col-xs-6">
                        <div v-if="hasOverwrittenDescription">
                            <DetailsText :field="{name: 'overwritten_description', label: 'Description (Custom)'}" :val="state.object.data.overwritten_description" @save="save" />
                           <br/>
                            <small>To restore the default description, click the "Description" label to edit the custom description, delete the contents, and save the field.</small>
                        </div>
                        <div v-else>
                            <DetailsText :field="{name: 'overwritten_description', label: 'Description'}" :val="state.object.data.description" @save="save" />
                        </div>
                    </div>
                    <div class="col-xs-6">
                        <div v-if="isAdapter || isCurbTablePriceType">
                            <DetailsSelect :field="{name: 'curb_price_category_id', label: 'Price Category', options: this.cache.priceCache.CATEGORIES }"
                                           :val="state.object.data.curb_price_category_id"
                                           @save="save"
                                           :inputAttributes="{disabled: isCurbTablePriceType}"
                                           :notSetLabel="'Not Set'"
                            />
                        </div>
                        <div v-else>
                            <DetailsStatic :field="{name: 'curb_price_category_id', label: 'Price Category'}" :val="`Set by Part ${state.object.data.new_part_num}`" />
                        </div>
                    </div>
                </div>
                <h3 v-show="!state.object.data.conforming">Files</h3>
                <Files :data="state.object.data" v-show="!state.object.data.conforming" @reload="load" />

                <h3>Details</h3>
                <Details :data="state.object.data" />

                <h3>Skid Dimensions</h3>
                <div class="panel panel-default">
                    <div class="row panel-body details_container">
                        <div class="col-lg-4 col-sm-6 col-xs-12">
                            <DetailsText :field="{
                                           name: 'skid_weight',
                                           label: 'Skid Weight',
                                         }"
                                         :val="this.state.object.data.skid_weight"
                                         @save="save"
                            />
                        </div>
                        <div class="col-lg-4 col-sm-6 col-xs-12">
                            <DetailsText :field="{
                                           name: 'skid_length',
                                           label: 'Skid Length (in)',
                                         }"
                                         :val="this.state.object.data.skid_length"
                                         @save="save"
                            />
                        </div>
                        <div class="col-lg-4 col-sm-6 col-xs-12">
                            <DetailsText :field="{
                                           name: 'skid_width',
                                           label: 'Skid Width (in)',
                                         }"
                                         :val="this.state.object.data.skid_width"
                                         @save="save"
                            />
                        </div>
                        <div class="col-lg-4 col-sm-6 col-xs-12">
                            <DetailsText :field="{
                                           name: 'skid_height',
                                           label: 'Skid Height (in)',
                                         }"
                                         :val="this.state.object.data.skid_height"
                                         @save="save"
                            />
                        </div>
                    </div>
                </div>

                <h3>Cost of Goods Data</h3>
                <div class="row">
                    <div class="col-sm-12">
                        <table class="table table-responsive">
                            <thead>
                            <tr>
                                <th>Name</th>
                                <th class="number">Cost</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Metal</td>
                                <td class="number">{{ Intl.NumberFormat('en-us' ,{style: 'currency', currency: 'USD'}).format(state.object.data.cogs_calcs.itemized.metal) }}</td>
                            </tr>
                            <tr>
                                <td>Insulation</td>
                                <td class="number">{{ Intl.NumberFormat('en-us' ,{style: 'currency', currency: 'USD'}).format(state.object.data.cogs_calcs.itemized.insulation) }}</td>
                            </tr>
                            <tr>
                                <td>Lumber</td>
                                <td class="number">{{ Intl.NumberFormat('en-us' ,{style: 'currency', currency: 'USD'}).format(state.object.data.cogs_calcs.itemized.lumber) }}</td>
                            </tr>
                            <tr>
                                <td>1 1/4 Inch AI</td>
                                <td class="number">{{ Intl.NumberFormat('en-us' ,{style: 'currency', currency: 'USD'}).format(state.object.data.cogs_calcs.itemized.one_and_qtr_ai) }}</td>
                            </tr>
                            <tr>
                                <td>2 Inch AI</td>
                                <td class="number">{{ Intl.NumberFormat('en-us' ,{style: 'currency', currency: 'USD'}).format(state.object.data.cogs_calcs.itemized.two_in_ai) }}</td>
                            </tr>
                            <tr>
                                <td>Gasket</td>
                                <td class="number">{{ Intl.NumberFormat('en-us' ,{style: 'currency', currency: 'USD'}).format(state.object.data.cogs_calcs.itemized.gasket) }}</td>
                            </tr>
                            <tr>
                                <td>Tube</td>
                                <td class="number">{{ Intl.NumberFormat('en-us' ,{style: 'currency', currency: 'USD'}).format(state.object.data.cogs_calcs.itemized.tubesteel) }}</td>
                            </tr>
                            <tr>
                                <td>Labor</td>
                                <td class="number">{{ Intl.NumberFormat('en-us' ,{style: 'currency', currency: 'USD'}).format(state.object.data.cogs_calcs.itemized.labor) }}</td>
                            </tr>
                            <tr>
                                <th>Pre-Markup</th>
                                <th class="number">{{ Intl.NumberFormat('en-us' ,{style: 'currency', currency: 'USD'}).format(state.object.data.cogs_calcs.subtotal) }}</th>
                            </tr>
                            <tr>
                                <td>Manufacturing Indirect Cost</td>
                                <td class="number">{{ Intl.NumberFormat('en-us', {style: 'currency', currency: 'USD'}).format(state.object.data.cogs_calcs.itemized.manufacturing_indirect) }}</td>
                            </tr>
                            <tr>
                                <td>Markup Amount</td>
                                <td class="number">{{ Intl.NumberFormat('en-us' ,{style: 'currency', currency: 'USD'}).format(state.object.data.cogs_calcs.itemized.markup) }}</td>
                            </tr>
                            <tr>
                                <th>Total with Markup</th>
                                <th class="number">{{ Intl.NumberFormat('en-us' ,{style: 'currency', currency: 'USD'}).format(state.object.data.cogs_calcs.itemized.total_w_markup) }}</th>
                            </tr>
                            <tr>
                                <td>LTL Shipping Cost</td>
                                <td class="number">{{ Intl.NumberFormat('en-us' ,{style: 'currency', currency: 'USD'}).format(state.object.data.cogs_calcs.itemized.ltl_shipping) }}</td>
                            </tr>
                            <tr>
                                <th>Total LTL</th>
                                <th class="number">{{ Intl.NumberFormat('en-us' ,{style: 'currency', currency: 'USD'}).format(state.object.data.cogs_calcs.total_w_ltl_shipping) }}</th>
                            </tr>
                            <tr>
                                <td>Truckload Shipping Cost</td>
                                <td class="number">{{ Intl.NumberFormat('en-us' ,{style: 'currency', currency: 'USD'}).format(state.object.data.cogs_calcs.itemized.truckload_shipping) }}</td>
                            </tr>
                            <tr>
                                <th>Total Truckload</th>
                                <th class="number">{{ Intl.NumberFormat('en-us' ,{style: 'currency', currency: 'USD'}).format(state.object.data.cogs_calcs.total_w_truckload_shipping) }}</th>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="panel panel-default">
                    <div class="row panel-body details_container">
                        <div class="col-lg-4 col-sm-6 col-xs-12">
                            <DetailsText :field="{ name: 'crated_height', label: 'Crated Height' }" :val="state.object.data.crated_height" @save="save" />
                        </div>
                        <div class="col-lg-4 col-sm-6 col-xs-12">
                            <DetailsText :field="{ name: 'crated_length', label: 'Crated Length' }" :val="state.object.data.crated_length" @save="save" />
                        </div>
                        <div class="col-lg-4 col-sm-6 col-xs-12">
                            <DetailsText :field="{ name: 'crated_width', label: 'Crated Width' }" :val="state.object.data.crated_width" @save="save" />
                        </div>
                        <div class="col-lg-4 col-sm-6 col-xs-12">
                            <DetailsText :field="{ name: 'insulation_sqft', label: 'Insulation Sq. Ft.' }" :val="state.object.data.insulation_sqft" @save="save" />
                        </div>
                        <div class="col-lg-4 col-sm-6 col-xs-12">
                            <DetailsText :field="{ name: 'one_and_qtr_in_angle_iron_by_ft', label: '1 1/4 In. AI/ft' }" :val="state.object.data.one_and_qtr_in_angle_iron_by_ft" @save="save" />
                        </div>
                        <div class="col-lg-4 col-sm-6 col-xs-12">
                            <DetailsText :field="{ name: 'two_in_angle_iron_by_ft', label: '2 In. AI/ft' }" :val="state.object.data.two_in_angle_iron_by_ft" @save="save" />
                        </div>
                        <div class="col-lg-4 col-sm-6 col-xs-12">
                            <DetailsText :field="{ name: 'gasket_roll_ct', label: 'Gasket /Roll' }" :val="state.object.data.gasket_roll_ct" @save="save" />
                        </div>
                        <div class="col-lg-4 col-sm-6 col-xs-12">
                            <DetailsText :field="{ name: 'lumber_2x4x12_by_board', label: '2x4x12 / Board' }" :val="state.object.data.lumber_2x4x12_by_board" @save="save" />
                        </div>
                        <div class="col-lg-4 col-sm-6 col-xs-12">
                            <DetailsText :field="{ name: 'cogs_metal_weight', label: 'CoGS Metal Weight' }" :val="state.object.data.cogs_metal_weight" @save="save" />
                        </div>
                        <div class="col-lg-4 col-sm-6 col-xs-12">
                            <DetailsText :field="{ name: 'cogs_labor_time', label: 'CoGS Labor Time' }" :val="state.object.data.cogs_labor_time" @save="save" />
                        </div>
                        <div class="col-lg-4 col-sm-6 col-xs-12">
                            <DetailsSelect :field="{ name: 'use_cogs_data', label: 'Calculation Strategy', options: [[1, 'Estimate CoGS by Value'], [2, 'Estimate CoGS by Material']] }" :val="state.object.data.use_cogs_data" @save="save" />
                        </div>
                    </div>
                </div>

            </div>
            <ConformingStamp :files="state.object.data.conforming_stamp_files"
                             :curbData="state.object.data"
                             @reload="load"
                             v-show="isCurrentView('conformingStamp')" />
            <Estimator v-show="isCurrentView('estimator')" :businessObject="'curb'" :curb="activeCurb" @changeView="changeView" />
            <Variants :variants="state.object.data.variant"
                      :defaults="variantDefaults"
                      :curbData="state.object.data"
                      v-show="isCurrentView('variants')"
            />
            <Lennox v-show="isCurrentView('lennox')" />
            <Stock :stock="stock" v-show="isCurrentView('stock')" />
            <History v-show="isCurrentView('history')" />

        </div>
        <div v-else>
            <Search>
                <template v-slot:menu>
                    <div class="btn-group btn-block btn_row">
                        <router-link class='btn btn-default' :to="'/cogs_upload'" >Upload CoGS Data</router-link>
                    </div>
                </template>
            </Search>
        </div>

    </div>
</template>

<script>
import { store } from '@/store/BusinessObject.store.js';
import { curbStore } from './Curb.store.js';
import Search from '@/components/businessObjects/utils/Search.vue';
import Details from "@/components/businessObjects/utils/Details";
import DetailsText from "@/components/businessObjects/utils/DetailsText";
import Variants from "@/components/businessObjects/curb/Variants";
import Lennox from "@/components/businessObjects/curb/Lennox";
import Stock from "@/components/businessObjects/curb/Stock";
import History from "@/components/businessObjects/utils/History";
import Files from "@/components/businessObjects/curb/Files";
import Estimator from "@/components/businessObjects/utils/Estimator";
import curbCache from "@/cache/curbType.cache";
import priceCache from "@/cache/price.cache";
import DetailsSelect from "@/components/businessObjects/utils/DetailsSelect";
import DetailsStatic from "@/components/businessObjects/utils/DetailsStatic";
import ConformingStamp from "@/components/businessObjects/curb/ConformingStamp";

export default {
    name: 'Container',
    data() {
        return {
            state: store.state,
            cache: {
                curbCache,
                priceCache
            },
            curbState: curbStore.state,
            variantDefaults: {},
            stock: {},
            activeCurb: {}
        }
    },
    props: ['id'],
    components: {
        ConformingStamp,
        DetailsStatic,
        DetailsSelect,
        Estimator,
        Files,
        History,
        Stock,
        Lennox,
        Variants,
        DetailsText,
        Details,
        Search
    },
    created() {
        store.initialize(this.curbState);
        this.load();
    },
    methods: {
        load: function() {
            if (this.id) {
                store.load(this.id)
                    .then(() => this.$appStore.setTitle([this.state.object.data.nomenclature, this.state.titlePlural]));
            }
        },
        isCurrentView: function(view) {
            return view === this.state.currentView;
        },
        save: function(data) {
            store.save(data.field, data.val);
        },
        changeView: function(data) {
            this.state.currentView = data.view;
        },
    },
    computed: {
        currentView: function() {
            return this.state.currentView;
        },
        hasOverwrittenDescription: function () {
            return this.state.object.data.overwritten_description;
        },
        isAdapter: function () {
            return this.state.object.data.curb_type_id === this.cache.curbCache.ADAPTER;
        },
        isCurbTablePriceType: function () {
            return this.state.object.data.is_curb_table_price_type;
        },
        fileType: function() {
            return this.$cdiVars.FILE_TYPE_THREED_DRWNG;
        },
    },
    watch: {
        id: function () {
            this.load();
        },
        currentView: function() {
            switch (this.state.currentView) {
                case 'history':
                    store.getHistory();
                    break;
                case 'variants':
                    store.api('get_variant_attribute_defaults_json').then(data => this.variantDefaults = data.defaults);
                    break;
                case 'stock':
                    store.api('get_stock').then(data => this.stock = data);
                    break;
                case 'estimator':
                    // Have to postpone the data initialization until tab click
                    this.activeCurb = this.state.object.data;
                    break;
            }
            if(this.currentView == 'search'){
                window.location.reload()
            }
        }
    }
}
</script>

<style scoped>

</style>