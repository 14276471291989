<template>
  <div class="spacing_top table-responsive">
    <div v-for="(curb_files, curb_type) in files" v-bind:key="curb_type">
      <h3>{{ curb_type }}</h3>
      <table class="table table-condensed">
        <tbody>
        <tr>
          <th>Conforming Year</th>
          <th>File</th>
          <th></th>
        </tr>
        <tr v-for="file in curb_files" v-bind:key="file.cdi_file_id">
          <td>
            {{ file.conforming_year }}
          </td>
          <td>
            <ProtectedLink :type="'download'" :linkType="'link'" :text="file.title" :id="file.cdi_file_id" :queryString="queryString" />
          </td>
          <td>
            <button class="btn btn-default" @click="$refs.deleteFile.open(file)"><span class="glyphicon glyphicon-trash"></span></button>
          </td>
        </tr>
        </tbody>
      </table>
      <div class="well well-sm">
        <button @click="upload()" class="btn btn-default conforming-button">
          <span class="glyphicon glyphicon-plus"></span>
        </button>
        <div class="conforming-button conforming-header">Add File</div>
      </div>
    </div>
    <Confirm ref="deleteFile" :heading="'Disable File?'" :body="'Would you like to disable this file?'" @response="deleteFile" />
  </div>
</template>

<script>
import { store } from '@/store/BusinessObject.store.js';
import ProtectedLink from "@/components/utils/ProtectedLink";
import helpers from "@/store/helpers";
import Confirm from "@/components/utils/Confirm";


export default {
  name: 'ConformingStamp',
  props: ['files', 'curbData'],
  components: {
    Confirm,
    ProtectedLink,
  },
  data() {
    return {
      state: store.state,
      formFields: {
        catalogNum: {val: '', qsField: '[catalog_num]'},
        lennoxPrice: {val: '', qsField: '[lennox_price]'}
      }
    }
  },
  methods: {
    deleteFile: function(file) {
        if (file) {
            store.apiWithObjectLoad('disable_conforming_file', {params: file.cdi_file_id});
        }
    },
    upload: function () {
      this.$appStore.setFileUploadContextData({
        referenceID: store.state.object.id,
        data: this.curbData
      });
      this.$appStore.setFileUploadContext('curb_conforming');
      this.$appStore.setFileUploadCallback(function () {
        store.load(store.state.object.id, 'conformingStamp');
      });
    },
  },
  computed: {
    queryString: function() {
      const params = helpers.getQSString({ 'params' : this.companyId});
      return `genid=${Math.floor(Math.random()*101)}&${params}`;
    }
  }
}
</script>
<style scoped>

.conforming-button{
  display: inline-block;
}

.conforming-header{
  padding: 1px 10px;
}
</style>