<template>
    <div>
        <input class='cdi_button btn btn-lg btn-primary' type='button' @click='showNewVariantForm = !showNewVariantForm' value='New Variant'>
        <br><br>
        <div>
            <div v-for="(variant, index) in variants" v-bind:key='index'>
                <div class="line_heading_container line_padding margin_bottom">{{ variant.label }}<span class="small pull-right">v{{ variant.curb_variant_id }}</span></div>
                <div class="line_body row">
                    <div class="col-lg-4 col-sm-6 col-xs-12">
                        <DetailsCheckbox :field='variantFields.turretReadyField' @save="save(variant.curb_variant_id, $event)" :val='variant.turret_ready' />
                    </div>
                    <div class="col-lg-4 col-sm-6 col-xs-12">
                        <DetailsCheckbox :field='variantFields.notKitField' @save="save(variant.curb_variant_id, $event)" :val='variant.not_kit' />
                    </div>
                    <div class="col-xs-12">
                        <DetailsText :field='variantFields.kitPath' @save="save(variant.curb_variant_id, $event)" :val='variant.kit_path' />
                    </div>
                  <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsCheckbox :field='variantFields.isHem' @save="save(variant.curb_variant_id, $event)" :val='variant.is_hem' />
                  </div>
                  <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsCheckbox :field='variantFields.insulationKit' @save="save(variant.curb_variant_id, $event)" :val='variant.insulation_kit' />
                  </div>
                  <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsCheckbox :field='variantFields.altBraceKit' @save="save(variant.curb_variant_id, $event)" :val='variant.alt_brace_kit' />
                  </div>
                </div>
                <div class="line_drawings well well-sm">
                    <ul class="list-inline line_drawings_list">
                        <li>
                            <button @click="upload(variant.curb_variant_id)" class="btn btn-default">
                                <span class="glyphicon glyphicon-plus"></span>
                            </button>
                        </li>
                        <li v-for='(drawing, drawingIndex) in variant.drawings' v-bind:key='drawingIndex'>
                            <ProtectedLink
                                :type="'download'"
                                :linkType="'iconText'"
                                :classes="'glyphicon glyphicon-file'"
                                :text="drawing.title"
                                :id="drawing.cdi_file_id"
                                :queryString="queryString"
                                :displayInline="true"
                            />&nbsp;
                            <span @click="showDeleteConfirm(drawing.cdi_file_id)" class="glyphicon glyphicon-remove pointer cdi_link"></span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <NewVariantModal :defaults="defaults" v-show='showNewVariantForm' @close='closeVariantModal' />

        <ConfirmBox
            v-if="showConfirm"
            :heading="'Disable'"
            :body="'Disable variant file?'"
            @close="disableVariantFile"
        />
    </div>
</template>

<script>
    import DetailsCheckbox from '@/components/businessObjects/utils/DetailsCheckbox';
    import DetailsText from '@/components/businessObjects/utils/DetailsText';
    import { store } from '@/store/BusinessObject.store.js';
    import helpers from "@/store/helpers";
    import NewVariantModal from './NewVariantModal'
    import ConfirmBox from "@/components/utils/ConfirmBox";
    import ProtectedLink from "@/components/utils/ProtectedLink";

    export default {
        name: 'Variants',
        props: ['variants', 'defaults', 'curbData'],
        components : {
            ConfirmBox,
            DetailsText,
            DetailsCheckbox,
            NewVariantModal,
            ProtectedLink,
        },
        data() {
            return {
                variantFields: {
                    turretReadyField: {
                        name: 'turret_ready',
                        label: 'Turret Ready'
                    },
                    notKitField: {
                        name: 'not_kit',
                        label: 'Not Kit'
                    },
                    kitPath: {
                        name: 'kit_path',
                        label: 'Kit Path'
                    },
                    isHem: {
                      name: 'is_hem',
                      label: 'Is Hem'
                    },
                    insulationKit: {
                      name: 'insulation_kit',
                      label: 'Insulation Kit'
                    },
                    altBraceKit: {
                      name: 'alt_brace_kit',
                      label: 'Alt Brace Kit'
                    },
                },
                state: store.state,
                showNewVariantForm: false,
                showConfirm: false,
                variantFileID: null,
                linkPrefix: process.env.VUE_APP_URL
            };
        },
        methods: {
            save: function(variantId, event) {
                let fields = {
                    curb_variant_id: {
                        val: variantId,
                        qsField: '[curb_variant_id]'
                    },
                    field: {
                        val: event.val,
                        qsField: '[' + event.field + ']'
                    }
                };
                store.apiWithObjectLoad('save_variant', helpers.getQSPairs(fields));
            },
            closeVariantModal() {
                this.showNewVariantForm = false;
            },
            upload: function (id) {
                this.$appStore.setFileUploadContextData({
                    referenceID: store.state.object.id,
                    reference2ID: id,
                    data: this.curbData
                });
                this.$appStore.setFileUploadContext('curb');
                this.$appStore.setFileUploadCallback(function () {
                    store.load(store.state.object.id, 'variants');
                });
            },
            showDeleteConfirm: function (id) {
                this.variantFileID = id;
                this.showConfirm = true;
            },
            disableVariantFile: function (confirm) {
                if (confirm) {
                    store.apiWithObjectLoad('disable_curb_variant_file', { 'params': this.variantFileID });
                }

                this.variantFileID = null;
                this.showConfirm = false;
            },
            queryString: function() {
                const params = helpers.getQSString({ 'params' : this.companyId});
                return `genid=${Math.floor(Math.random()*101)}&${params}`;
            }
        }
    }
</script>