<template>
    <div>
        <h3>Available</h3>
        <form>
            <div class='table-responsive'>
                <table class="table">
                    <thead>
                        <tr>
                            <th>Location</th>
                            <th class="number">Available</th>
                            <th class="number">Allocated</th>
                            <th class="number">Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for='(quantity, index) in this.getStockData.quantity' v-bind:key='index'>
                            <td>{{ quantity.cdi_location }}</td>
                            <td class="number">{{ quantity.available }}</td>
                            <td class="number">{{ quantity.allocated }}</td>
                            <td class="number">{{ quantity.total }}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="4">
                                <label><input type='checkbox' v-model='state.object.data.move_it' :true-value=1 :false-value=0 @click='moveIt'> Move It</label>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </form>

        <h3>Adjustment</h3>
        <form>
            <div class="row">
                <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                    <label>Location</label>
                    <select class='form-control' v-model='forms.adjustmentForm.cdiLocation.val'>
                        <option v-for='[cdiLocationId, cdiLocation] in cache.cdiLocationCache.CDI_LOCATIONS' v-bind:key='cdiLocationId' :value='cdiLocationId'>
                            {{ cdiLocation }}
                        </option>
                    </select>
                </div>
                <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                    <label>Quantity</label>
                    <input type="text" v-model='forms.adjustmentForm.quantity.val' class="form-control">
                </div>
                <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                    <label>Reason</label>
                    <select class='form-control' v-model='forms.adjustmentForm.reasonId.val'>
                        <option v-for='[id, reason] in cache.stockAdjustment.REASONS' v-bind:key='id' :value='id'>
                            {{ reason }}
                        </option>
                    </select>
                </div>
                <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                    <label>Note</label>
                    <input type="text" v-model='forms.adjustmentForm.note.val' class="form-control">
                </div>
            </div>

            <input class='btn btn-default' type='button' value='Save' @click='saveStockAdjustment'>
        </form>

        <h3>History</h3>
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Location</th>
                        <th class="number">Quantity</th>
                        <th>Name</th>
                        <th>Reason</th>
                        <th>Order ID</th>
                        <th>Note</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for='(history, index) in this.getStockData.history' v-bind:key='index'>
                        <td>{{ history.transaction_date }}</td>
                        <td>{{ history.cdi_location }}</td>
                        <td class="number">{{ history.quantity }}</td>
                        <td>{{ history.user_name }}</td>
                        <td>{{ history.cdi_lookup }}</td>
                        <td>{{ (history.tracker_id) ? history.tracker_id : '' }}</td>
                        <td>{{ history.note }}</td>
                        <td>{{ (history.allocated) ? 'Allocated' : '' }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import { store } from '@/store/BusinessObject.store.js';
    import helpers from "@/store/helpers";
    import cdiLocationCache from '@/cache/cdiLocation.cache.js';
    import stockAdjustment from '@/cache/stockAdjustment.cache.js';

    export default {
        name: 'Stock',
        props: ['stock'],
        data() {
            return {
                state: store.state,
                cache: {
                    cdiLocationCache,
                    stockAdjustment
                },
                forms: {
                    moveItForm: {
                        moveIt: {val: 0, qsField: '[move_it]'}
                    },
                    adjustmentForm: {
                        cdiLocation: {val: 1, qsField: '[cdi_location_id]'},
                        quantity: {val: '', qsField: '[quantity]'},
                        reasonId: {val: 253, qsField: '[reason_id]'},
                        note: {val: '', qsField: '[note]'}
                    }
                },
                stockData: {}
            }
        },
        methods: {
            moveIt: function () {
                this.forms.moveItForm.moveIt.val = (this.state.object.data.move_it) ? 0 : 1;
                store.apiWithObjectLoad('save_curb', helpers.getQSPairs(this.forms.moveItForm));
            },
            saveStockAdjustment: function () {
                store.api('save_stock_adjustment', helpers.getQSPairs(this.forms.adjustmentForm)).then(data => {
                    this.stockData = data;
                    this.resetDefaults();
                });
            },
            resetDefaults: function () {
                this.forms.adjustmentForm.cdiLocation.val = 1;
                this.forms.adjustmentForm.quantity.val = '';
                this.forms.adjustmentForm.reasonId.val = 253;
                this.forms.adjustmentForm.note.val = '';
            }
        },
        computed: {
            getStockData: function () {
                return Object.keys(this.stockData).length > 0 ? this.stockData : this.stock;
            }
        }
    }
</script>