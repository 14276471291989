<template>
    <form>
        <div class="row spacing_top">
            <div class="form-group col-sm-6 col-xs-12">
                <label>Catalog #</label>
                <input type='text' v-model='state.object.data.catalog_num' class="form-control">
            </div>
            <div class="form-group col-sm-6 col-xs-12">
                <label>Price</label>
                <input type='text' v-model='state.object.data.lennox_price' class="form-control">
            </div>
        </div>
        <input class='btn btn-default' type='button' value='Save Item' @click="saveLennoxPricing">
    </form>
</template>

<script>
    import { store } from '@/store/BusinessObject.store.js'
    import helpers from "@/store/helpers";

    export default {
        name: 'Lennox',
        data() {
            return {
                state: store.state,
                formFields: {
                    catalogNum: {val: '', qsField: '[catalog_num]'},
                    lennoxPrice: {val: '', qsField: '[lennox_price]'}
                }
            }
        },
        methods: {
            saveLennoxPricing: function() {
                let catalogNumber = this.state.object.data.catalog_num;

                this.formFields.catalogNum.val = catalogNumber;
                this.formFields.lennoxPrice.val = this.state.object.data.lennox_price;

                if (catalogNumber == 0 || catalogNumber == "") {
                    this.formFields.catalogNum.val = 'remove';
                }

                store.apiWithObjectLoad('save_lennox', helpers.getQSPairs(this.formFields));
            }
        }
    }
</script>